<template>
  <div>
    <slot />
    <div>
      <div>
        <!-- <ColorScheme placeholder="" tag="span">
          Color mode: <b>{{ $colorMode.preference }}</b>
          <span v-if="$colorMode.preference === 'system'">(<i>{{ $colorMode.value }}</i> mode detected)</span>
        </ColorScheme> -->
        <!-- <h1>Color mode: {{ $colorMode.value }}</h1> -->
        <!-- <select v-model="$colorMode.preference">
          <option value="system">
            System
          </option>
          <option value="light">
            Light
          </option>
          <option value="dark">
            Dark
          </option>
          <option value="sepia">
            Sepia
          </option>
        </select> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

// Set html and body classes for Tailwind full screen
useHead({
  bodyAttrs: {
    class: 'h-full'
  },
  htmlAttrs: {
    class: 'h-full bg-white'
  }
})

// const colorMode = useColorMode()
// console.log(colorMode.preference)

</script>

<style scoped>

</style>
